<template>
<PointBase :loader_prop="loader_prop">
 delete customer
 </PointBase >
</template>

<script>
import BackendModel from "../../models/BackendModel";
import PointBase from "./PointBase";
import('./point/css/style.css')
export default {
  name: 'PointDeleteCustomer',
  components: {
    PointBase
  },
  data() {
    return {
   user_id :'',
   loader_prop: true
    }
  },
   async mounted () {
    let backendModel = new BackendModel()
        let user_id = this.$route.query.id
        await backendModel.point_Request("/Api/service/backoffice/point_delete_customer", {user_id})
        await this.$router.push({ path: '/point/point_customer' }) 
        this.loader_prop =false
   },
 
}
</script>

<style>
@import 'point/css/style.css';
</style>
